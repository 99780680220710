import React, { useState, useEffect, useRef } from 'react';
import mqtt from 'mqtt';
import SearchingScreen from './components/SearchingScreen';
import SingleSonde from './components/SingleSonde';
import MultipleSondes from './components/MultipleSondes';
import Modal from './components/Modal';
import './App.css';

function App() {
    const [loading, setLoading] = useState(true);
    const [sondes, setSondes] = useState([]);
    const [selectedSonde, setSelectedSonde] = useState(null); // État pour la sonde sélectionnée
    const [isModalOpen, setIsModalOpen] = useState(false); // État pour l'ouverture du modal
    const clientRef = useRef(null);

    useEffect(() => {
        if (!clientRef.current) {
            const client = mqtt.connect('wss://broker.hivemq.com:8884/mqtt', {
                reconnectPeriod: 1000,
            });

            clientRef.current = client;

            client.on('connect', () => {
                console.log('Connecté au broker MQTT');

                client.subscribe('cesi_weather_simulator/measures', (err) => {
                    if (err) {
                        console.log('Erreur lors de la souscription au topic:', err);
                    } else {
                        console.log('Souscription au topic réussie');
                    }
                });
            });

            client.on('message', (topic, message) => {
                console.log('Message reçu:', topic, message.toString());

                try {
                    const data = JSON.parse(message.toString());

                    const newSonde = {
                        name: data.name,
                        temp: `${data.measures.find(m => m.label === 'temperature').valeur}°C`,
                        hum: `${data.measures.find(m => m.label === 'humidity').valeur}%`,
                        press: `${data.measures.find(m => m.label === 'pressure').valeur} hPa`,
                    };

                    setSondes((prevSondes) => {
                        const sondeIndex = prevSondes.findIndex(sonde => sonde.name === newSonde.name);

                        if (sondeIndex !== -1) {
                            // Mettre à jour la sonde existante
                            const updatedSondes = [...prevSondes];
                            updatedSondes[sondeIndex] = newSonde;
                            return updatedSondes;
                        } else {
                            // Ajouter une nouvelle sonde
                            return [...prevSondes, newSonde];
                        }
                    });

                    setLoading(false);
                } catch (error) {
                    console.log('Erreur lors du parsing du message:', error);
                }
            });

            client.on('error', (err) => {
                console.log('Erreur de connexion MQTT:', err);
            });
        }

        return () => {
            if (clientRef.current) {
                clientRef.current.end();
                clientRef.current = null;
            }
        };
    }, []);

    const openModal = (sonde) => {
        setSelectedSonde(sonde);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedSonde(null);
    };

    return (
        <div>
            {loading && <SearchingScreen />}
            {sondes.length === 1 && (<SingleSonde sonde={sondes[0]} onClick={() => openModal(sondes[0])} />)}
            {sondes.length > 1 && (
                <MultipleSondes sondes={sondes} onClick={openModal} />
            )}

            {isModalOpen && selectedSonde && (
                <Modal sonde={selectedSonde} onClose={closeModal} />
            )}
        </div>
    );
}

export default App;
