import React from 'react';
import '../App.css';
import { CiTempHigh } from "react-icons/ci";
import { WiHumidity } from "react-icons/wi";
import { IoBeer } from "react-icons/io5";

function SingleSonde({ sonde, onClick }) {
    return (
        <div onClick={onClick}>
            <div className="container">
            <h1 className="title">Meteo</h1>
                <div className="sonde-data">
                    <h2>{sonde.name}</h2>
                    <p><span class="icone"><CiTempHigh /></span>  {sonde.temp}</p>
                    <p><span class="icone"><WiHumidity /></span>  {sonde.hum}</p>
                    <p><span class="icone"><IoBeer /></span>  {sonde.press}</p>
                </div>
            </div>
        </div>
    );
}

export default SingleSonde;
