

function Modal({ sonde, onClose }) {
    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <h2>Détails de la {sonde.name}</h2>
                <p>Température : {sonde.temp}</p>
                <p>Humidité : {sonde.hum}</p>
                <p>Pression : {sonde.press}</p>
                <button className="close-button" onClick={onClose}>Fermer</button>
            </div>
        </div>
    );
}

export default Modal;
