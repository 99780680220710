// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
    max-width: 1000px; /* Limite la largeur de la modal pour qu'elle ne dépasse pas l'écran */
    padding: 20px;
    background: #fff;
    border-radius: 10px;
}

.charts-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.chart-item {
    flex: 1 1; /* Chaque graphique occupe une proportion égale */
    min-width: 0; /* Permet aux graphiques de se redimensionner correctement */
}

.chart-item canvas {
    width: 100% !important; /* Le graphique occupera tout l'espace disponible dans son conteneur */
    height: auto !important; /* Conserve le ratio de hauteur approprié */
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #ff4b4b;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.close-button:hover {
    background-color: #ff6b6b;
}
`, "",{"version":3,"sources":["webpack://./src/components/Modal.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB,EAAE,sEAAsE;IACzF,aAAa;IACb,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAS;AACb;;AAEA;IACI,SAAO,EAAE,iDAAiD;IAC1D,YAAY,EAAE,4DAA4D;AAC9E;;AAEA;IACI,sBAAsB,EAAE,sEAAsE;IAC9F,uBAAuB,EAAE,2CAA2C;AACxE;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".modal-content {\n    max-width: 1000px; /* Limite la largeur de la modal pour qu'elle ne dépasse pas l'écran */\n    padding: 20px;\n    background: #fff;\n    border-radius: 10px;\n}\n\n.charts-container {\n    display: flex;\n    justify-content: space-between;\n    gap: 20px;\n}\n\n.chart-item {\n    flex: 1; /* Chaque graphique occupe une proportion égale */\n    min-width: 0; /* Permet aux graphiques de se redimensionner correctement */\n}\n\n.chart-item canvas {\n    width: 100% !important; /* Le graphique occupera tout l'espace disponible dans son conteneur */\n    height: auto !important; /* Conserve le ratio de hauteur approprié */\n}\n\n.modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    background: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.close-button {\n    margin-top: 20px;\n    padding: 10px 20px;\n    background-color: #ff4b4b;\n    color: white;\n    border: none;\n    cursor: pointer;\n    border-radius: 5px;\n}\n\n.close-button:hover {\n    background-color: #ff6b6b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
