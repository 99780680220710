import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Import axios
import "./Modal.css";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function Modal({ sonde, onClose }) {
    // État pour stocker les données récupérées
    const [data, setData] = useState({
        tempData: [],
        humData: [],
        pressData: [],
        labels: []
    });

    // Fonction pour récupérer les données de la sonde avec axios
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://backend.cv-dev.ovh/sensor/${sonde.mac}/10`);

                // Extraire les 10 dernières mesures pour chaque type (température, humidité, pression)
                const tempData = response.data.mesures.temperature.map(item => parseFloat(item.valeur.$numberDecimal));
                const humData = response.data.mesures.humidity.map(item => parseFloat(item.valeur.$numberDecimal));
                const pressData = response.data.mesures.pressure.map(item => parseFloat(item.valeur.$numberDecimal));

                // Créer des labels basés sur les timestamps ou autres valeurs
                const labels = response.data.mesures.temperature.map(item => new Date(item.timestamp).toLocaleTimeString());

                // Mettre à jour l'état avec les nouvelles données
                setData({
                    tempData,
                    humData,
                    pressData,
                    labels
                });
            } catch (error) {
                console.error('Erreur lors de la récupération des données:', error);
            }
        };

        fetchData();
    }, [sonde.mac]); // Dépend de sonde.mac pour recharger les données si sonde change

    // Données pour le graphique de température
    const tempChartData = {
        labels: data.labels,
        datasets: [
            {
                label: 'Température',
                data: data.tempData, // Utiliser les données récupérées
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                fill: false,
            }
        ]
    };

    // Données pour le graphique d'humidité
    const humChartData = {
        labels: data.labels,
        datasets: [
            {
                label: 'Humidité',
                data: data.humData, // Utiliser les données récupérées
                borderColor: 'rgba(54, 162, 235, 1)',
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                fill: false,
            }
        ]
    };

    // Données pour le graphique de pression
    const pressChartData = {
        labels: data.labels,
        datasets: [
            {
                label: 'Pression',
                data: data.pressData, // Utiliser les données récupérées
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: false,
            }
        ]
    };

    // Options de personnalisation du graphique
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: `Graphique des 10 dernières données`,
            },
        },
    };

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>

                <h2>Détails de la sonde de {sonde.name}</h2>

                {/* Conteneur pour afficher les graphiques côte à côte */}
                <div className="charts-container">
                    {/* Graphique de la température */}
                    <div className="chart-item">
                        <h3>Température</h3>
                        <Line data={tempChartData} options={options} />
                    </div>

                    {/* Graphique de l'humidité */}
                    <div className="chart-item">
                        <h3>Humidité</h3>
                        <Line data={humChartData} options={options} />
                    </div>

                    {/* Graphique de la pression */}
                    <div className="chart-item">
                        <h3>Pression</h3>
                        <Line data={pressChartData} options={options} />
                    </div>
                </div>
                <button className="close-button" onClick={onClose}>Fermer</button>
            </div>
        </div>
    );
}

export default Modal;
