import React from 'react';
import '../App.css';

function SingleSonde({ sonde, onClick }) {
    return (
        <div onClick={onClick}>
            <div className="container">
                <h1 className="title">Meteo</h1>
                <div className="sonde-data">
                    <p>Name: {sonde.name}</p>
                    <p>Temp: {sonde.temp}</p>
                    <p>Hum: {sonde.hum}</p>
                    <p>Press: {sonde.press}</p>
                </div>
            </div>
        </div>
    );
}

export default SingleSonde;
