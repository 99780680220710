import React from 'react';
import '../App.css';

function SearchingScreen() {
    return (
        <div className="container">
            <h1 className="title">Meteo</h1>
            <div className="loader">
                <p>Searching for sondes</p>
            </div>
        </div>
    );
}

export default SearchingScreen;
