import React from 'react';
import '../App.css';

function MultipleSondes({ sondes, onClick }) {
    return (
        <div> 
            <div className="container">
                <h1 className="title">Meteo</h1>
                <div className="sondes-list">
                    {sondes.map((sonde, index) => (
                        <div key={index} onClick={() => onClick(sonde)} className="sonde-item">
                            <div className="sonde-data">
                                <p>Name: {sonde.name}</p>
                                <p>Temp: {sonde.temp}</p>
                                <p>Hum: {sonde.hum}</p>
                                <p>Press: {sonde.press}</p>
                            </div>
                        </div>
                    ))} 
                </div>
            </div>
        </div>
    );
}
export default MultipleSondes;

